import React from "react";
import Form from "../components/services/Form";
import Services from "../components/Services";

const ServicePage = () => {
  return (
    <>
      <Form />
      <Services />
    </>
  );
};

export default ServicePage;
