import React from "react";

const Cancellation = () => {
    return (
        <div className="compalinace-page">
  <div className="compalinace-content">
    <div className="content-container">
      <p className="content-head">Cancellation &amp; Refund Policy</p>
      <div className="content-seprater" />
      <p className="updated-date">Last updated on Aug 2nd 2023</p>
      <p className="content-text">
        No cancellations &amp; Refunds are entertained
      </p>
    </div>
  </div>
</div>

    );
};

export default Cancellation;