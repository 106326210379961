import React, { useState, useEffect } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import logo from "../img/logo.webp";
import WOW from "wowjs";
import { analytics } from "../firebase.js";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const NavigationBar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const location = useLocation();
  const page = location.pathname.substring(1);
  const pageBackgrounds = {
    "": "bg-header",
    "web-app-development": "bg-header-webapp",
    "mobile-app-development": "bg-header-mobapp",
    "digital-marketing": "bg-header-digmar",
    "id-card-print": "bg-header-id",
    "brand-designs": "bg-header-graphic",
    // Add more pages here as needed
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isBlogPost = location.pathname.includes('/blog/');

  const getFontSizeClass = () => {
    return isBlogPost ? 'h1' : 'display-4';
  };

  const formatPageName = (pageName) => {
    if (isBlogPost) {
      const parts = pageName.split('/');
      return parts[parts.length - 1].replace(/-/g, ' ');
    }
    return pageName.charAt(0).toUpperCase() + pageName.slice(1);
  };

  useEffect(() => {
    const fetchBlogTitle = async () => {
      if (isBlogPost) {
        const titleSlug = location.pathname.split('/').pop();
        const decodedTitle = decodeURIComponent(titleSlug).replace(/_/g, " ");
        
        try {
          const blogDocRef = doc(db, "blogs", "blogDoc");
          const blogDocSnap = await getDoc(blogDocRef);

          if (blogDocSnap.exists()) {
            const blogsData = blogDocSnap.data().blogs;
            const foundPost = blogsData.find(blog => 
              blog.title.toLowerCase().replace(/[^a-z0-9]/g, "") === 
              decodedTitle.toLowerCase().replace(/[^a-z0-9]/g, "")
            );

            if (foundPost) {
              setBlogTitle(foundPost.title);
            }
          }
        } catch (error) {
          console.error("Error fetching blog title: ", error);
        }
      }
    };

    fetchBlogTitle();
  }, [location.pathname, isBlogPost]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Adjust these values based on your needs
  const maxWidth = 280; // Max width for larger screens
  const minWidth = 200; // Min width for smaller screens
  const breakpoint = 380; // Example breakpoint for mobile devices

  const imgWidth = windowWidth < breakpoint ? `${minWidth}px` : `${maxWidth}px`;

  useEffect(() => {
    const checkScrollTop = () => {
      if (!isSticky && window.pageYOffset > 45) {
        setSticky(true);
      } else if (isSticky && window.pageYOffset <= 45) {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [isSticky]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, [location.pathname]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar
        expand="lg"
        className={`px-5 py-3 py-lg-0 ${
          isSticky ? "sticky-top shadow-sm" : ""
        }`}
        variant="dark"
        expanded={isOpen}
      >
        <Container fluid>
          <div className="navbar-header">
            <Navbar.Brand as={Link} to="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: imgWidth, height: "auto" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarCollapse" onClick={toggle}>
              <span className="fa fa-bars"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="navbarCollapse">
            <Nav className="ms-auto">
              <Nav.Link
                as={NavLink}
                to="/"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Home_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/about"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "About_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/features"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Feature_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Features
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/services"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Service_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/testimonial"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Testimonial_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Testimonial
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/blogs"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Blog_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Blogs
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/contact"
                activeclassname="active"
                onClick={() => {
                  logEvent(analytics, "Contact_page", {
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    page_title: document.title,
                  });
                  toggle();
                }}
              >
                Contact
              </Nav.Link>
            </Nav>
            <Button
              as={Link}
              to="/quote"
              className="text-white py-2 px-4 ms-3"
              style={{ background: "#f3b457" }}
              onClick={() => {
                logEvent(analytics, "Quote_page", {
                  page_location: window.location.href,
                  page_path: window.location.pathname,
                  page_title: document.title,
                });
                toggle();
              }}
            >
              Request a Quote
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {page !== "" && (
        <div
          className={`container-fluid py-5 ${
            pageBackgrounds[page] || "bg-header"
          }`}
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center text-break">
              <h1 className={`${getFontSizeClass()} text-white animated zoomIn`}>
              {isBlogPost ? blogTitle || "Blog Post" : formatPageName(page)}
              </h1>
              <div>
                <Link to="/" className="h5 text-white">
                  Home
                </Link>
                {isBlogPost ? (
                  <>
                    <i className="far fa-circle text-white px-2"></i>
                    <Link to="/blogs" className="h5 text-white">
                      Blog
                    </Link>
                  </>
                ) : (
                  <>
                    <i className="far fa-circle text-white px-2"></i>
                    <span className="h5 text-white">
                      {formatPageName(page)}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavigationBar;
