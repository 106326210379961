import React from "react";
import img from "../img/feature.webp";

const Features = () => {
  return (
    <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container pb-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-uppercase" style={{ color: "#f3b457" }}>
            Why Choose Us
          </h5>
          <h1 className="mb-0">
            We Are Here to Grow Your Business Exponentially
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                <div
                  className="rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60, background: "#f3b457" }}
                >
                  <i
                    className="fa fa-cubes"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4>Standard Quality</h4>
                <p className="mb-0">
                  We assure relatively good performance of all the services
                  offered under it by enforcing highly accessible resources.
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                <div
                  className="rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60, background: "#f3b457" }}
                >
                  <i
                    className="fa fa-award"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4>Strong Market</h4>
                <p className="mb-0">
                  Enrope Solutions embarks on a development towards a contagious
                  form of technology and engaging millions of keen users.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4  wow zoomIn"
            data-wow-delay="0.9s"
            style={{ minHeight: 350 }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.1s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="feature"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row g-5">
              <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                <div
                  className="rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60, background: "#f3b457" }}
                >
                  <i
                    className="fa fa-users-cog"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4>Efficient Performance</h4>
                <p className="mb-0">
                  We guarantee consistent efforts in building efficient markets
                  with strong capabilities, fast deliveries and huge advances.
                </p>
              </div>
              <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                <div
                  className="rounded d-flex align-items-center justify-content-center mb-3"
                  style={{ width: 60, height: 60, background: "#f3b457" }}
                >
                  <i
                    className="fa-brands fa-reddit"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4>Retained Interest</h4>
                <p className="mb-0">
                  Our swift process in each step involved, keep us ahead of
                  others and add value to the retention of users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
