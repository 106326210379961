import React, { useState, useRef } from "react";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

const Quote = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [service, setService] = useState("");
  const navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaResponseChange = (value) => {
    setCaptchaValue(value);
  };
  const recaptchaRef = useRef();

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value) || value === '') {
      setName(value);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) || value === '') {
      setMobile(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if fields are filled
    if (!name) {
      alert("Name is required");
      return;
    }

    if (!email) {
      alert("Email is required");
      return;
    }

    if (!service) {
      alert("Service is required");
      return;
    }
    if (!captchaValue) {
      alert("Please verify you are a human!");
      return;
    }
    // Send data to Firestore
    try {
      const quoteDoc = doc(db, "formData", "quotesDoc");
      await updateDoc(quoteDoc, {
        quotes: arrayUnion({
          name,
          email,
          mobile,
          service,
          timestamp: new Date(),
          isVisible: true,
        }),
      });

      // Clear the form
      setName("");
      setEmail("");
      setMobile("");
      setService("");
      recaptchaRef.current.reset();

      // Redirect to thank you page
      navigate('/thank-you');
    } catch (error) {
      console.error("Error submitting quote: ", error);
    }
  };

  return (
    <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container pb-5">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title position-relative pb-3 mb-5">
              <h5
                className="fw-bold text-uppercase"
                style={{ color: "#f3b457" }}
              >
                Request A Quote
              </h5>
              <h1 className="mb-0">
                Need A Free Quote? Please Feel Free to Contact Us
              </h1>
            </div>
            <div className="row gx-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-4">
                  <i
                    className="fa fa-reply me-3"
                    style={{ color: "#f3b457" }}
                  />
                  Instant Revert
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-4">
                  <i
                    className="fa fa-phone-alt fa-flip-horizontal me-3"
                    style={{ color: "#f3b457" }}
                  />
                  Constant Support
                </h5>
              </div>
            </div>
            <p className="mb-4">
              Since its inception in 2018, our slogan has been to develop
              simpler and more appealing solutions in its diverse area. We go
              over your requirements and shape your thought process. We will
              assist you in increasing the efficiency of your work and producing
              better outcomes.
            </p>
            <div
              className="d-flex align-items-center mt-2 wow zoomIn"
              data-wow-delay="0.6s"
            >
              <div
                className="d-flex align-items-center justify-content-center rounded"
                style={{ width: 60, height: 60, background: "#f3b457" }}
              >
                <a href="tel:+91 7303 441 550">
                  <i className="fa fa-phone text-white" />
                </a>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                {/*                             <h4 class="mb-0" style="color:#f3b457">+012 345 6789</h4> */}
                <h4 className="mb-0" style={{ color: "#f3b457" }}>
                  <a
                    href="tel:+91 7303 441 550"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    +91 7303 441 550
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="rounded h-100 d-flex align-items-center p-5 wow zoomIn"
              data-wow-delay="0.9s"
              style={{ background: "#f3b457" }}
            >
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-xl-12">
                    <input
                      type="text"
                      className="form-control bg-light border-0"
                      placeholder="Your Name *"
                      style={{ height: 55 }}
                      name="name"
                      required
                      autoComplete="name"
                      value={name}
                      onChange={handleNameChange}
                      
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control bg-light border-0"
                      placeholder="Your Email *"
                      style={{ height: 55 }}
                      name="email"
                      required
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-12">
                  <input
                    type="tel"
                    className="form-control bg-light border-0"
                    placeholder="Your Mobile Number"
                    style={{ height: 55 }}
                    name="mobile"
                    autoComplete="tel"
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                  </div>
                  <div className="col-12">
                    {/*      <select class="form-select bg-light border-0" style="height: 55px;">
                                  <option selected>Select A Service</option>
                              <option value="1">Service 1</option>
                                <option value="2">Service 2</option>
                                  <option value="3">Service 3</option>
                                 </select> */}
                    <select
                      name="sel"
                      className="form-select bg-light border-0"
                      id="language-select"
                      style={{ height: 55 }}
                      // onFocus="this.size=6;"
                      // onBlur="this.size=0;"
                      // onChange="this.size=1; this.blur()"
                      required
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                    >
                      <option
                        select=""
                        style={{ background: "#f3b457 !important" }}
                      >
                        Select A Service *
                      </option>
                      ==
                      <option value="Web App. Development" id="opt1">
                        Web App. Development
                      </option>
                      <option value="Mobile App. Development" id="opt2">
                        Mobile App. Development
                      </option>
                      <option value="Digital Marketing" id="opt3">
                        Digital Marketing
                      </option>
                      <option value="ID Card Print" id="opt4">
                        ID Card Print
                      </option>
                      <option value="Designing&Branding" id="opt5">
                        Designing&amp;Branding
                      </option>
                    </select>
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center">
                  <div className="responsive">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfU56wpAAAAAKIWM7IqySvgIUuxH-BGaCDbIT_7"
                      onChange={handleCaptchaResponseChange}
                    />
                  </div>
                  </div>
                  <div className="col-12 responsive">
                    <button
                      name="submit"
                      className="btn btn-dark w-100 py-3"
                      type="submit"
                    >
                      Request A Quote
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
