import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from "./Form";
import { Helmet } from "react-helmet-async";

const Google = () => {
  return (
    <>
    <Helmet>
      <title>Google Ads Experts | PPC Campaigns - Delhi NCR</title>
      <meta
        name="description"
        content="Maximize your ROI with expert Google Ads management in Delhi NCR. Our PPC specialists create targeted campaigns to drive leads and sales."
      />
    </Helmet>
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Elevate your organization's presence with Google Ads services
                from Enrope Solutions!
              </h2>
              <p className="mb-4 text-justify" style={{ textAlign: "justify" }}>
                Google Ads offers a variety of benefits for businesses looking
                to increase their online visibility and reach their target
                audience.  Some of the key benefits include: 
                <br />
                <br />
                <strong>Targeted Reach:</strong> Google Ads allows you to target
                your ads to specific demographics, interests, and even keywords,
                ensuring that your ads are seen by the people most likely to be
                interested in your products or services. 
                <br />
                <br />
                <strong> Measurable Results:</strong> Google Ads provides
                detailed tracking and reporting tools that allow you to track
                the performance of your ads, including clicks, impressions,
                conversions, and more. This data can help you optimize your
                campaigns and improve your return on investment (ROI). 
                <br />
                <br />
                <strong> Increased Website Traffic:</strong> By appearing at the
                top of search results or on relevant websites, Google Ads can
                significantly increase the traffic to your website, bringing in
                potential customers who are actively searching for your products
                or services. 
                <br />
                <br />
                <strong> Brand Awareness:</strong> Even if users don't click on
                your ads immediately, seeing your brand name and message
                repeatedly can increase brand awareness and make them more
                likely to choose you in the future. 
              </p>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              {/* <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={img}
                  style={{ objectFit: "cover" }}
                  alt="about"
                /> */}
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Google Ads"/>
    </div>
    </>
  );
};

export default Google;
