import React from "react";
import img from "../../img/website_page.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import html from "../../img/HTML5.webp";
import css from "../../img/CSS3.webp";
import bootstrap from "../../img/Bootstrap.webp";
import react from "../../img/React.webp";
import php from "../../img/PHP.webp";
import laravel from "../../img/Laravel.webp";
import mysql from "../../img/MySQL.webp";
import firebase from "../../img/Firebase.webp";
import Form from "./Form";
import { Helmet } from "react-helmet-async";

const Web = () => {
  return (
    <>
    <Helmet>
      <title>Enrope Solutions - Web Development Experts - Delhi NCR</title>
      <meta
        name="description"
        content="Elevate your online presence with Enrope Solutions, the leading web development experts in Delhi NCR. We specialize in crafting custom websites that drive results."
      />
    </Helmet>
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Expert Web Design & Development in Delhi NCR - Craft your
                digital presence
              </h2>
              <p className="mb-4" style={{ textAlign: "justify" }}>
                In today's digital landscape, a well-crafted website is no
                longer a luxury - it's a necessity.
                <br />
                 <br />
                Here at Enrope Solutions, we're a leading website development
                company in Delhi NCR, specializing in transforming your vision
                into a website that not only looks stunning, but also drives
                results. Our team of expert web designers and developers boasts
                a unique blend of creativity and technical prowess, ensuring
                flawless web design and development for your company website
                design.
                <br />
                 <br />
                Whether you require a simple brochure website or a complex
                e-commerce platform, we offer a comprehensive suite of web
                design services. We can handle everything from initial concept
                and design to development and launch, ensuring a seamless
                experience. Our web design and development approach prioritize
                user experience (UX) and search engine optimization (SEO),
                guaranteeing a website that not only resonates with your target
                audience but also ranks high on search engine results pages.
                <br />
                 <br />
                So, if you're searching for a reliable web design and
                development company in Delhi NCR to design your business
                website, look no further!
                <br />
                 <br />
                Contact Enrope Solutions today and let's craft a website that
                exceeds expectations and propels your business forward.
              </p>
              <p>
                <b>Technologies we use:</b>
              </p>
              <p className="heading__desc mb-30">
                <b>Front End:</b> <br />
              </p>
              <div className="" style={{}}>
                <img src={html} width="80px;" height="auto;" alt="" />
                <img
                  style={{ marginLeft: "1rem" }}
                  src={css}
                  width="60px;"
                  height="auto;"
                  alt=""
                />
                <img
                  style={{ marginLeft: "1.2rem", marginTop: "1rem" }}
                  src={bootstrap}
                  width="80px;"
                  height="auto;"
                  alt=""
                />
                <img
                  style={{ marginLeft: "1.2rem", marginTop: "1rem" }}
                  src={react}
                  width="80px;"
                  height="auto;"
                  alt=""
                />
              </div>
              <p />
              <br />
              <p className="heading__desc mb-30">
                <b>Backend:</b> <br />
              </p>
              <div style={{}}>
                <img
                  style={{ marginTop: "1%" }}
                  src={php}
                  width="80px;"
                  height="auto;"
                  alt=""
                />
                <img
                  style={{ marginLeft: "1.5rem" }}
                  src={laravel}
                  width="70px;"
                  height="auto;"
                  alt=""
                />
                <img
                  style={{ marginLeft: "1rem" }}
                  src={mysql}
                  width="80px;"
                  height="auto;"
                  alt=""
                />
                <img
                  style={{ marginLeft: "1rem" }}
                  src={firebase}
                  width="80px;"
                  height="auto;"
                  alt=""
                />
              </div>
              <p />

              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Web App. Development" />
    </div>
    </>
  );
};

export default Web;
