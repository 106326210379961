import { useState, useRef, useEffect } from "react";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../../firebase.js";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

const Form = ({ defaultService }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [services, setServices] = useState(defaultService ? [defaultService] : []);
  const navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaResponseChange = (value) => {
    setCaptchaValue(value);
  };
  const recaptchaRef = useRef();

  useEffect(() => {
    if (defaultService) {
      setServices([defaultService]);
    }
  }, [defaultService]);

  const handleServiceChange = (e) => {
    if (e.target.checked) {
      setServices([...services, e.target.value]);
    } else {
      setServices(services.filter((service) => service !== e.target.value));
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value) || value === '') {
      setName(value);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) || value === '') {
      setMobile(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if fields are filled
    if (!name) {
      alert("Name is required");
      return;
    }

    if (!email) {
      alert("Email is required");
      return;
    }

    if (!mobile) {
      alert("Mobile number is required");
      return;
    }

    if (services.length === 0) {
      alert("At least one service is required");
      return;
    }

    if (!captchaValue) {
      alert("Please verify you are a human!");
      return;
    }

    // Send data to Firestore
    try {
      const serviceDoc = doc(db, "formData", "serviceDoc");
      await updateDoc(serviceDoc, {
        service: arrayUnion({
          name,
          email,
          mobile,
          services,
          timestamp: new Date(),
          isVisible: true,
        }),
      });

      // Clear the form
      setName("");
      setEmail("");
      setMobile("");
      setServices([]);
      recaptchaRef.current.reset();

      navigate('/thank-you');
    } catch (error) {
      console.error("Error submitting Request: ", error);
    }
  };

  return (
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5" style={{ background: "#F5F5F5" }}>
        <div className="text-center mx-auto" style={{ maxWidth: 900 }}>
          <form onSubmit={handleSubmit}>
            <div className="section-title text-center position-relative pb-3">
              <h1 className="mb-0">Request A Call-back</h1>
            </div>
            <div className="row g-3 pt-5 pb-5">
              <div className="col-md-8">
                <div className="col-xl-12">
                  <input
                    type="text"
                    className="form-control border-0 mb-4"
                    placeholder="Your Name *"
                    style={{ height: 60 }}
                    name="name"
                    required
                    autoComplete="name"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control border-0 mb-4"
                    placeholder="Your Email *"
                    style={{ height: 60 }}
                    name="email"
                    required
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-12 pb-3">
                  <input
                    type="tel"
                    className="form-control border-0"
                    placeholder="Your Mobile Number *"
                    style={{ height: 60 }}
                    name="mobile"
                    required
                    autoComplete="tel"
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="row pb-3">
                  <div className="col-12 text-start">
                    <h6
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Services Required *≻
                    </h6>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Web App. Development"
                        id="service1"
                        onChange={handleServiceChange}
                        checked={services.includes("Web App. Development")}
                      />
                      <label className="form-check-label" htmlFor="service1">
                        Web App. Development
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Mobile App. Development"
                        id="service2"
                        onChange={handleServiceChange}
                        checked={services.includes("Mobile App. Development")}
                      />
                      <label className="form-check-label" htmlFor="service2">
                        Mobile App. Development
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Digital Marketing"
                        id="service4"
                        onChange={handleServiceChange}
                        checked={services.includes("Digital Marketing")}
                      />
                      <label className="form-check-label" htmlFor="service4">
                        Digital Marketing
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="ID Card Print"
                        id="service5"
                        onChange={handleServiceChange}
                        checked={services.includes("ID Card Print")}
                      />
                      <label className="form-check-label" htmlFor="service5">
                        ID Card Print
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Designing & Branding"
                        id="service6"
                        onChange={handleServiceChange}
                        checked={services.includes("Designing & Branding")}
                      />
                      <label className="form-check-label" htmlFor="service6">
                        Designing & Branding
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Social Media Management"
                        id="service7"
                        onChange={handleServiceChange}
                        checked={services.includes("Social Media Management")}
                      />
                      <label className="form-check-label" htmlFor="service7">
                      Social Media Management
                      </label>
                    </div>
                    <div className="form-check pb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="Google Ads"
                        id="service8"
                        onChange={handleServiceChange}
                        checked={services.includes("Google Ads")}
                      />
                      <label className="form-check-label" htmlFor="service8">
                      Google Ads
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LfU56wpAAAAAKIWM7IqySvgIUuxH-BGaCDbIT_7"
                  onChange={handleCaptchaResponseChange}
                />
              </div>
              <div className="col-12">
                <button
                  name="submit"
                  className="btn btn-primary btn-lg w-50 py-3"
                  type="submit"
                  style={{ background: "#f3b457" }}
                >
                  Send Request
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
