import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from "./Form";
import { Helmet } from "react-helmet-async";

const Social = () => {
  return (
    <>
    <Helmet>
      <title>Social Media Management Delhi NCR | Expert Social Media Managers</title>
      <meta
        name="description"
        content="Boost your brand's online presence with expert social media management in Delhi NCR. Find experienced social media managers to craft engaging content, drive growth, and increase brand awareness."
      />
    </Helmet>
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Elevate your organization's presence with social media
                management services from Enrope Solutions!
              </h2>
              <p className="mb-4 text-justify" style={{ textAlign: "justify" }}>
                <strong>Social Media Optimization (SMO):</strong> Our
                client-focused approach to SMO involves crafting strategies that
                boost engagement, visibility, and growth, while also providing
                direct customer support through messaging to ensure prompt
                resolution of inquiries.    
                <br />
                  
                <br />
                <strong>Social Media Advertising:</strong> We design and execute
                targeted ad campaigns on platforms like Facebook, Instagram,
                LinkedIn, and Twitter, optimizing ad spend and targeting
                specific demographics to maximize reach and ROI.      
                <br />
                  
                <br />
                <strong>Social Media Strategy Development:</strong> We create
                customized social media strategies tailored to your business
                goals, target audience, and industry trends, ensuring effective
                and coherent online presence across all platforms. 
              </p>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              {/* <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Social Media Management" />
    </div>
    </>
  );
};

export default Social;
