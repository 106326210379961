import React from "react";
import img from "../../img/id-card.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from "./Form";

const IdPrint = () => {
  return (
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Elevate your organization's security and professionalism with
                top-notch ID card services from Enrope Solutions!
              </h2>
              <p className="mb-4 text-justify" style={{ textAlign: "justify" }}>
                We offer a comprehensive suite of ID card printing solutions to
                cater to all your needs, from schools and businesses to events
                and communities.
                <br />
                 <br />
                Looking for high-quality school ID card printing near you? We've
                got you covered. Our services extend to corporate ID card
                printing, ensuring your employees carry a professional and
                secure identification badge. Custom ID card printing is our
                specialty, allowing you to personalize your cards with logos,
                photos, and security features like magnetic strips or barcodes.
                We utilize PVC ID card material renowned for its durability and
                professional appearance.   
                <br /> <br />
                We understand the importance of a complete solution. That's why
                we offer custom PVC card printing alongside custom lanyard ID
                card printing. Choose from a variety of lanyard colors and
                styles to perfectly complement your ID cards.   
                <br /> <br />
                Get started with ID card print near you today!  Enrope Solutions
                guarantees exceptional quality, fast turnaround times, and
                competitive prices.   
                <br /> <br />
                Contact us now to discuss your specific requirements and receive
                a free quote.
              </p>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="ID Card Print" />
    </div>
  );
};

export default IdPrint;
