import React from "react";
import {Audio } from "react-loader-spinner";

const PageLoader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      zIndex: 9999,
    }}
  >
    <Audio color="#f3b457" height={100} width={100} timeout={3000} />
  </div>
);

export default PageLoader;