import React from "react";

const Thanks = () => {
  return (
    <div className="container text-center">
      <h1>Thank you for your submission!</h1>
      <p>
        We will review your submission and get back to you as soon as possible.
      </p>
    </div>
  );
};

export default Thanks;