import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Topbar,
  Navbar,
  About,
  Footer,
  Features,
  Testimonial,
  Contact,
  Quote,
  Backtt,
  Pricing,
  Privacy,
  Terms,
  Cancellation,
  Shipping,
  Web,
  Mobile,
  DigMarket,
  IdPrint,
  Designing,
  ServicePage,
  Home,
} from "./components";
import TestimonialForm from "./components/TestimonialForm";
import Thanks from "./components/Thanks";
import Social from "./components/services/Social";
import Google from "./components/services/Google";
import Posts from "./components/blog/posts/Posts";
import SinglePost from "./components/blog/singlePost/SinglePost";
import FilteredBlogs from "./components/blog/filteredBlogs/FilteredBlogs";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Enrope Solutions</title>
        <meta
          name="description"
          content="Enrope Solutions | Website Development | Digital Marketing | Mobile Application Development | Branding"
        />
        <meta
          name="keywords"
          content="Enrope Solutions, Website Development, Digital Marketing, Mobile Application Development, Branding"
        />
      </Helmet>
      <Topbar />
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/features" element={<Features />} />
          <Route exact path="/services" element={<ServicePage />} />
          <Route exact path="/web-app-development" element={<Web />} />
          <Route exact path="/mobile-app-development" element={<Mobile />} />
          <Route exact path="/digital-marketing" element={<DigMarket />} />
          <Route exact path="/id-card-print" element={<IdPrint />} />
          <Route exact path="/brand-designs" element={<Designing />} />
          <Route exact path="/social-media-management" element={<Social />} />
          <Route exact path="/google-ads" element={<Google />} />
          <Route exact path="/testimonial" element={<Testimonial />} />
          <Route exact path="/testimonial-form" element={<TestimonialForm />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blogs" element={<Posts />} />
          <Route path="/blog/:title" element={<SinglePost />} />
          <Route path="/blogs/label/:label" element={<FilteredBlogs />} />
          <Route exact path="/quote" element={<Quote />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/cancellation" element={<Cancellation />} />
          <Route exact path="/shipping" element={<Shipping />} />
          <Route exact path="/thank-you" element={<Thanks />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Backtt />
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
