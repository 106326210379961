import React from "react";
import img from "../../img/digital_page.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from "./Form";
import { Helmet } from "react-helmet-async";

const DigMarket = () => {
  return (
    <>
    <Helmet>
      <title>Digital Marketing: Content & Email Experts - Delhi NCR</title>
      <meta
        name="description"
        content="Boost your online presence with our expert digital marketing services in Delhi NCR. We specialize in creating engaging content and effective email campaigns to drive results."
      />
    </Helmet>
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Increase the traffic for your website and generate the revenues
                with Enrope Solutions
              </h2>
              <p className="mb-4 text-justify" style={{ textAlign: "justify" }}>
                Brand awareness is a must for the business and can be fulfilled
                through Digital Marketing. Following the ongoing trend of
                spreading abundant ideas and implementing digital tactics,
                Keyfill Light encompasses all the marketing efforts digitally.
                It leverages digital channels like search engines, social media,
                email, and other websites etc to reach and connect with current
                and prospective customers. With the use of digital marketing,
                you can reach an enormous audience in such a way that is both
                cost-effective and quantifiable. Our dedicated team of experts
                will help you in increasing traffic, sales, and revenue of your
                business which makes us the leading Digital Marketing Company in
                Delhi NCR.
                <br />
                <br />
                <strong>We are expertise in:</strong>
                <br />
                ● Search Engine Optimization
                <br />
                ● Search Engine Marketing
                <br />
                ● Social Media Optimization
                <br />
                ● Google AdWords/ PPC
                <br />
                ● Local SEO
                <br />
                ● Content Marketing
                <br />
                ● Email Marketing
                <br />
                ● Google Ads
                <br />
                ● Facebook Marketing
                <br />
                ● Instagram Marketing
                <br />
                ● YouTube Advertising
                <br />
                ● YouTube Channel Management
                <br />
              </p>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Digital Marketing" />
    </div>
    </>
  );
};

export default DigMarket;
