import React from "react";
import img from "../../img/mobile_app_page.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import andriod from "../../img/Android.webp";
import ios from "../../img/IOS.webp";
import hybrid from "../../img/Hybrid.webp";
import swift from "../../img/Swift.webp";
import kotlin from "../../img/Kotlin.webp";
import dart from "../../img/Dart.webp";
import flutter from "../../img/Flutter.webp";
import Form from "./Form";
import { Helmet } from "react-helmet-async";

const Mobile = () => {
  return (
    <>
    <Helmet>
      <title>Enrope Solutions - Top Mobile App Developers - Delhi NCR</title>
      <meta
        name="description"
        content="Looking for the best mobile app developers in Delhi NCR? Find top-rated app development companies specializing in Android and iOS apps. Get your dream app built today."
      />
    </Helmet>
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                Delhi NCR's Trusted Mobile App Development Partner - Delivering
                Cutting-Edge iOS & Android Solutions 
              </h2>
              <p className="mb-4" style={{ textAlign: "justify" }}>
                In today's mobile-driven world, a captivating app can be the
                game-changer for your business. At Enrope Solutions, we're a
                leading mobile application development company catering to both
                iOS and Android platforms.  Our team of expert  ios app
                developers and  android app developers  possesses the skills and
                experience to craft  iphone app development services and 
                android application development services that are not only
                visually stunning but also deliver exceptional user experiences.
                  
                <br />
                 <br />
                Whether you need a  top app development company to build a 
                native  ios app or a powerful  android app, we've got you
                covered. We offer comprehensive  iphone application development
                companies  and  android development company  services,
                encompassing everything from concept and design to development,
                launch, and ongoing maintenance.  Looking to target both major
                mobile operating systems? Our team of  ios and android app
                developers  can seamlessly create a unified app experience
                across  iphone app development services and  android app
                development service.   
                <br />
                 <br />
                So, if you're searching for a reliable  ios app development
                company or  android application development company  in Delhi
                NCR  – look no further!     
                <br />
                 <br />
                Contact Enrope Solutions today and let's discuss how a
                custom-built mobile app can propel your business forward.
              </p>

              <>
                <p
                  className="heading__desc mb-30 "
                  style={{ textAlign: "justify" }}
                >
                  <b>We are involved in constructing:</b> <br />
                </p>
                <div>
                  <img
                    style={{ paddingLeft: "1.5rem" }}
                    src={andriod}
                    width="90px;"
                    height="auto;"
                    alt=""
                  />
                  <img
                    style={{ paddingLeft: "1.5rem" }}
                    src={ios}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                  <img
                    style={{ paddingLeft: "1.5rem" }}
                    src={hybrid}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                </div>
                <p />
                <br />
                <p className="heading__desc mb-30">
                  <b>Technologies used in Mobile Applications:</b> <br />
                  <br />
                  <img
                    style={{ paddingLeft: "1.5rem" }}
                    src={swift}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                  <img
                    style={{ paddingLeft: "1.5rem" }}
                    src={kotlin}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                  <br />
                  <img
                    style={{ paddingLeft: "1.5rem", marginTop: "1.5rem" }}
                    src={dart}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                  <img
                    style={{ paddingLeft: "1.5rem", marginTop: "1.5rem" }}
                    src={flutter}
                    width="90px"
                    height="auto;"
                    alt=""
                  />
                </p>
              </>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Mobile App. Development" />
    </div>
    </>
  );
};

export default Mobile;
