import React from "react";
import img from "../../img/graphic_page.webp";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Form from "./Form";

const Designing = () => {
  return (
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="pb-3 mb-5">
              <h2 className="mb-4 section-title position-relative pb-3 mb-5">
                We will help you to establish as a brand through attractive
                designs for your business
              </h2>
              <p className="mb-4 text-justify" style={{ textAlign: "justify" }}>
                Enrope Solutions is indulged in strengthening the brand image
                through creating designs that engage the targeted audience and
                grab the attention instantly. We envisage the requirements and
                turn them into designs that partners with brands like and hence
                become the company's greatest asset.
                <br />
                <br /> We are prominent in creating effective designs by our
                experts, using advanced software and are well versed with the
                tools, which makes us the leading Graphic Designing Company in
                Delhi NCR.
                <br />
                <br />
                <strong>We creative enough in providing:</strong>
                <br />
                <br />
                ● Logos:
                <br />
                Typographic logos, Character logos
                <br />
                <br />
                ● Stationery Designs:
                <br />
                Visiting cards, Letterheads, Envelops
                <br />
                <br />
                ● Designs for Advertisement:
                <br />
                Print Media, Magazine Ads
                <br />
                <br />
                ● Prototypes and Wireframes:
                <br />
                Website UI/UX, Mobile Application UI/UX
                <br />
                <br />
                ● Web Graphics:
                <br />
                Website Banners, Social Media Graphics,
                <br />
                Youtube Thumbnails, Channel Cover and Arts, Infographics
                <br />
                <br />
                ● Other expertise:
                <br />
                Brochures, Flyers, Leaflets, Menus
              </p>
              <p className="col-8 pt-5 text-center">
                If you have any questions or need help, feel free to contact
                with our team, or you can call us any time
                <Link to="tel:+91 7303 441 550">(+91) 7303 441 550</Link>
              </p>
              <Link
                to="/contact"
                className="btn btn_primary col-7 d-flex justify-content-center"
              >
                <Button style={{ background: "#f3b457" }}>Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: 500 }}>
            <div className="position-relative h-75">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src={img}
                style={{ objectFit: "cover" }}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
      <Form defaultService="Designing & Branding" />
    </div>
  );
};

export default Designing;
