import React, { useState, useRef } from "react";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaResponseChange = (value) => {
    setCaptchaValue(value);
  };
  const recaptchaRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if fields are filled
    if (!name) {
      alert("Name is required");
      return;
    }

    if (!email) {
      alert("Email is required");
      return;
    }

    if (!subject) {
      alert("Subject is required");
      return;
    }
    if (!captchaValue) {
      alert("Please verify you are a human!");
      return;
    }
    // Send data to Firestore
    try {
      const contactDoc = doc(db, "formData", "contactDoc");
      await updateDoc(contactDoc, {
        contact: arrayUnion({
          name,
          email,
          subject,
          message,
          timestamp: new Date(),
          isVisible: true,
        }),
      });

      // Clear the form
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      recaptchaRef.current.reset();

      navigate('/thank-you');
    } catch (error) {
      console.error("Error submitting query: ", error);
    }
  };

  return (
    <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container-fluid pb-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-uppercase" style={{ color: "#f3b457" }}>
            Contact Us
          </h5>
          <h1 className="mb-0">
            If You Have Any Query, Feel Free To Contact Us
          </h1>
        </div>
        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div
              className="d-flex align-items-start wow fadeIn"
              data-wow-delay="0.1s"
            >
              <div
                className="d-flex align-items-center justify-content-center rounded icon-container"
                style={{ width: 60, height: 60, background: "#f3b457" }}
              >
                <a href="tel:+91 8448 362 250">
                  <i
                    className="fa fa-phone text-white"
                    style={{ marginTop: 5 }}
                  />
                </a>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                {/*  <h4 class="mb-0">+012 345 6789</h4> */}
                <h4 className="mb-0" style={{ color: "#f3b457" }}>
                  <a
                    href="tel:+91 8448 362 250"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    +91 8448 362 250
                  </a>
                  <br />
                  <a
                    href="tel:+91 7303 441 550"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    +91 7303 441 550 (Sales)
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="d-flex align-items-start wow fadeIn"
              data-wow-delay="0.4s"
            >
              <div
                className="d-flex align-items-center justify-content-center rounded icon-container"
                style={{
                  width: 60,
                  height: 60,
                  background: "#f3b457",
                }}
              >
                <a href="mailto:contact@enropesolutions.com">
                  <i
                    className="fa fa-envelope text-white"
                    style={{ marginTop: 5 }}
                  />
                </a>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Email to get free quote</h5>
                <h4 className="mb-0" style={{ color: "#f3b457" }}>
                  <a
                    href="mailto:contact@enropesolutions.com"
                    style={{ color: "inherit", textDecoration: "none", wordWrap: "break-word", wordBreak: "break-all" }}
                  >
                    contact@enropesolutions.com
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4" style={{ marginLeft: 0 }}>
            <div
              className="d-flex align-items-start wow fadeIn"
              data-wow-delay="0.8s"
            >
              <div
                className="d-flex align-items-center justify-content-center rounded icon-container"
                style={{ width: 60, height: 60, background: "#f3b457" }}
              >
                <i
                  className="fa fa-map-marker-alt text-white"
                  style={{ marginTop: 5 }}
                />
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Visit our office</h5>
                <h4 className="mb-0" style={{ color: "#f3b457" }}>
                  Unit B 002, B 91, Sector 2,
                  <br /> Noida, Uttar Pradesh
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control form-select border-0 px-4"
                    placeholder="Your Name *"
                    style={{ height: 55, background: "#F5F5F5" }}
                    name="name"
                    autoComplete="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}        
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                    }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control border-0 px-4"
                    placeholder="Your Email *"
                    style={{ height: 55, background: "#F5F5F5" }}
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control border-0 px-4"
                    placeholder="Subject *"
                    style={{ height: 55, background: "#F5F5F5" }}
                    name="subject"
                    autoComplete="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control border-0 px-4 py-3"
                    rows={4}
                    placeholder="Message"
                    style={{ background: "#F5F5F5" }}
                    name="message"
                    autoComplete="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfU56wpAAAAAKIWM7IqySvgIUuxH-BGaCDbIT_7"
                    onChange={handleCaptchaResponseChange}
                  />
                </div>
                <div className="col-12">
                  <button
                    name="submit"
                    className="text-white w-100 py-3"
                    type="submit"
                    style={{ background: "#f3b457", border: "none" }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
            <div
              style={{
                position: "relative",
                paddingBottom: "75%",
                height: 0,
                overflow: "hidden",
              }}
            >
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14013.23753777355!2d77.330722!3d28.590494!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4ffb73eeb8b%3A0x6ff3bcbc88a10e48!2sEnrope%20Solutions!5e0!3m2!1sen!2sin!4v1688782068692!5m2!1sen!2sin"
                style={{
                  border: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
