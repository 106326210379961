import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBNZDditjKa0YJC6W5_4K_ubWiAhJv-Ux4",
  authDomain: "enrope-website.firebaseapp.com",
  projectId: "enrope-website",
  storageBucket: "enrope-website.appspot.com",
  messagingSenderId: "992708735810",
  appId: "1:992708735810:web:88cba1ebea8bcd072fcedb",
  measurementId: "G-5506JGWEF2"
};

// Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(firebaseApp);
// Initialize Firestore and get a reference to the service
export const db = getFirestore(firebaseApp);
// Initialize Storage and get a reference to the service
export const storage = getStorage(firebaseApp);