import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "testimonials", "testDoc");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setTestimonials(docSnap.data().testimonials);
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return <div></div>; // Display a loading message while fetching the data
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '25%',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '15%',
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '5%',
        }
      }
    ]
  };

  return (
    <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container pb-5">
        <div
          className="section-title text-center position-relative pb-3 mb-4 mx-auto"
          style={{ maxWidth: 600 }}
        >
          <h5 className="fw-bold text-uppercase" style={{ color: "#f3b457" }}>
            Testimonial
          </h5>
          <h1 className="mb-0">What Our Clients Say About Our Services</h1>
        </div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="testimonial-item my-4"
            >
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                {testimonial.imageUrl ? (
                  <img
                    src={testimonial.imageUrl}
                    alt={testimonial.name}
                    style={{ width: 40, height: 40 }}
                  />
                ) : (
                  <i
                    className="fa fa-user"
                    aria-hidden="true"
                    style={{ fontSize: 40, color: "lightgray" }}
                  />
                )}
                <div className="ps-4 testimonial-info">
                  <p className="mb-1" style={{ color: "black" }}>
                    {testimonial.name}
                  </p>
                  <small className="text-uppercase">
                    {testimonial.designation}
                  </small>
                  <p className="mb-1" style={{ color: "#808080" }}>
                    {testimonial.businessName}
                  </p>
                </div>
              </div>
              <div className="testimonial-text pt-4 pb-5 px-5">{testimonial.testimonial}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;