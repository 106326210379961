import React, { useEffect } from "react";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import About from "../components/About";
import Features from "../components/Features";
import Services from "../components/Services";
import Quote from "../components/Quote";
import Testimonial from "../components/Testimonial";
import { db, storage } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import Carousel from "react-bootstrap/Carousel";
import PageLoader from "../components/PageLoader";
import { Helmet } from "react-helmet-async";

const fetchSliderImages = async () => {
  const docRef = doc(db, "slider", "sliderDoc");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const slider = docSnap.data().slider;
    for (let i = 0; i < slider.length; i++) {
      const slide = slider[i];
      const url = await getDownloadURL(ref(storage, slide.sliderImg));
      slide.sliderImg = url;
      new Image().src = url;
    }
    return slider;
  } else {
    throw new Error("No such document!");
  }
};

const queryClient = new QueryClient();

const Home = () => {
  const { data: slider, isLoading } = useQuery({
    queryKey: ["sliderImages"],
    queryFn: fetchSliderImages,
  });

  useEffect(() => {
    if (isLoading) {
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "visible";
    }
  }, [isLoading]);

  if (isLoading) {
    return <PageLoader />; // Show the PageLoader while the data is loading
  }
  return (
    <>
      <Helmet>
        <title>
          Enrope Solutions - Web, App & Digital Marketing - Delhi NCR
        </title>
        <meta
          name="description"
          content="Enrope Solutions: Your one-stop shop for web development, app development, and digital marketing in Delhi NCR. We deliver innovative solutions to boost your online presence."
        />
      </Helmet>

      <style type="text/css">
        {`
          .carousel-indicators {
          display: none;
          }
        `}
      </style>
      <Carousel fade>
        {slider &&
          slider.map((slide, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 carousel-img"
                src={slide.sliderImg}
                alt=""
              />
              <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    {slide.title1}
                  </h5>
                  <h1
                    className="display-1 text-white mb-md-4 animated zoomIn"
                    style={{ fontSize: 35 }}
                  >
                    {slide.title2}
                  </h1>
                  <Link
                    to="/quote"
                    className="btn text-white py-md-3 px-md-5 me-3 animated slideInLeft"
                    style={{ background: "#f3b457" }}
                  >
                    Request a Quote
                  </Link>
                  <Link
                    to="/contact"
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>

      {/* Facts */}
      <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div
                className="shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px", background: "#f3b457" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i
                    className="fa fa-users"
                    style={{ color: "black", fontSize: "22px" }}
                  ></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Happy Clients</h5>
                  <h1 className="text-white mb-0">
                    <CountUp end={15} duration={4} delay={2} />
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px", background: "#f3b457" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center rounded mb-2"
                  style={{
                    width: "60px",
                    height: "60px",
                    background: "#f3b457",
                  }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-0" style={{ color: "#f3b457" }}>
                    Projects Done
                  </h5>
                  <h1 className="mb-0" style={{ color: "#f3b457" }}>
                    <CountUp end={20} duration={4} delay={2} />
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px", background: "#f3b457" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i
                    className="fa fa-award"
                    style={{ color: "black", fontSize: "22px" }}
                  ></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Years of Experience</h5>
                  <h1 className="text-white mb-0">
                    <CountUp
                      end={10}
                      duration={4}
                      delay={2}
                      formattingFn={(value) => `${value}+`}
                    />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <About />
        <Features />
        <Services />
        <Quote />
        <Testimonial />
      </div>
    </>
  );
};

export default function WrappedHome() {
  return (
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  );
}
