import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { TailSpin } from "react-loader-spinner";
import "./filteredBlogs.css";

export default function FilteredBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { label } = useParams();
  const navigate = useNavigate();

  const decodeLabel = (encodedLabel) => {
    return decodeURIComponent(encodedLabel.replace(/_/g, ' '));
  };

  const encodeTitle = (title) => {
    const cleanTitle = title.replace(/[^a-zA-Z0-9\s_]/g, '');
    return encodeURIComponent(cleanTitle.replace(/ /g, '_'));
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogDocRef = doc(db, "blogs", "blogDoc");
        const blogDocSnap = await getDoc(blogDocRef);

        if (blogDocSnap.exists()) {
          const allBlogs = blogDocSnap.data().blogs;
          const decodedLabel = decodeLabel(label);
          const filteredBlogs = allBlogs.filter(blog => 
            blog.labels.split(",").map(l => l.trim().toLowerCase()).includes(decodedLabel.toLowerCase())
          );
          setBlogs(filteredBlogs);
        } else {
          setError("No blogs found");
        }
      } catch (error) {
        console.error("Error fetching blogs: ", error);
        setError("Error fetching blogs");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [label]);

  const handleShowAllBlogs = () => {
    navigate("/blogs");
  };

  const handleShare = (event, blog) => {
    event.preventDefault();
    const blogUrl = `${window.location.origin}/blog/${encodeTitle(blog.title)}`;
    
    if (navigator.share) {
      navigator.share({
        title: blog.title,
        text: blog.metaDescription,
        url: blogUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(blogUrl)
        .then(() => alert('Link copied to clipboard!'))
        .catch((error) => console.error('Error copying link: ', error));
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <TailSpin height="80" width="80" color="#00BFFF" ariaLabel="loading" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const decodedLabel = decodeLabel(label);

  return (
    <div className="filteredBlogs">
      <Helmet>
        <title>{`Blogs tagged with ${decodedLabel} | Enrope Solutions`}</title>
        <meta name="description" content={`Read blogs tagged with ${decodedLabel}`} />
      </Helmet>
      <div className="filterBanner">
        <h2>Showing blogs with the Label: {decodedLabel}</h2>
        <button onClick={handleShowAllBlogs} className="showAllButton">Show All Blogs</button>
      </div>
      <div className="blogList">
        {blogs.map((blog, index) => (
          <Link to={`/blog/${encodeTitle(blog.title)}`} className="post" key={index}>
            <div>
              <img
                className="postImg"
                src={blog.imageUrl}
                alt={blog.title}
              />
              <span className="postDate">{blog.date}</span>
              <div className="postInfo">
                <span className="postTitle">{blog.title}</span>
              </div>
              <p className="postDesc">{blog.metaDescription}</p>
              <div className="shareButtonContainer">
                <button onClick={(e) => handleShare(e, blog)} className="shareButton">
                  <i className="fa fa-share" />
                </button>
              </div>
              <p className="postCat">Read More</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}