import React from "react";

const Shipping = () => {
  return (
    <div className="compalinace-page">
      <div className="compalinace-content">
        <div className="content-container">
          <p className="content-head">Shipping &amp; Delivery Policy</p>
          <div className="content-seprater" />
          <p className="updated-date">Last updated on Aug 2nd 2023</p>
          <p className="content-text">
            Shipping is not applicable for business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
