import React from "react";
import { Link } from "react-router-dom";
import "./post.css";

export default function Post({ post }) {
  const encodeTitle = (title) => {
    // Remove any characters that are not letters, numbers, spaces, or underscores
    const cleanTitle = title.replace(/[^a-zA-Z0-9\s_]/g, '');
    // Replace spaces with underscores and encode the result
    return encodeURIComponent(cleanTitle.replace(/ /g, '_'));
  };

  const handleShare = (event) => {
    event.preventDefault();
    const blogUrl = `${window.location.origin}/blog/${encodeTitle(post.title)}`;
    
    if (navigator.share) {
      navigator.share({
        title: post.title,
        text: post.metaDescription,
        url: blogUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      // Fallback for browsers that don't support navigator.share
      navigator.clipboard.writeText(blogUrl)
        .then(() => alert('Link copied to clipboard!'))
        .catch((error) => console.error('Error copying link: ', error));
    }
  };

  return (
    <Link to={`/blog/${encodeTitle(post.title)}`} className="post">
    <div>
      <img
        className="postImg"
        src={post.imageUrl}
        alt={post.title}
      />
      <span className="postDate">{post.date}</span>
      <div className="postInfo">
        <span className="postTitle">          
            {post.title}          
        </span>          
      </div>
      <p className="postDesc">
        {post.metaDescription}
      </p>
      <div className="shareButtonContainer">
      <button onClick={handleShare} className="shareButton"><i className="fa fa-share" /></button>
      </div>
      <p className="postCat">Read More</p>
    </div>
    </Link>
  );
}