import React from "react";

const Topbar = () => {
  return (
    <>
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <small className="mb-0 text-white" style={{ color: "#f3b457" }}>
              <i className="fa fa-map-marker me-2"/>
              Unit B 002, B 91, Sector 2, Noida, Uttar Pradesh
              </small>
              &nbsp; &nbsp;
              <small className="me-3 text-light">
                <i className="fa fa-phone me-2" />
                <a
                  href="tel:+91 7303 441 550"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  +91 7303 441 550
                </a>
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-o" aria-hidden="true" />
                &nbsp;&nbsp;
                <a
                  href="mailto:contact@enropesolutions.com"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  contact@enropesolutions.com
                </a>
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: 45 }}
            >
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://twitter.com/enropesolutions"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.facebook.com/enropesolutions"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.linkedin.com/company/enrope-solutions/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in fw-normal" />
              </a>
              <a
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                href="https://www.instagram.com/enropesolutions/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram fw-normal" />
              </a>
              {/* <a class="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i
                      class="fab fa-youtube fw-normal"></i></a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
