import { useState, useRef } from "react";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase.js";
import ReCAPTCHA from "react-google-recaptcha";

const TestimonialForm = () => {
  const [fullName, setFullName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [positiveReview, setPositiveReview] = useState("");
  const [feedback, setFeedback] = useState("");

  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaResponseChange = (value) => {
    setCaptchaValue(value);
  };
  const recaptchaRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks (simplified for brevity)
    if (
      !fullName ||
      !designation ||
      !companyName ||
      !positiveReview ||
      !captchaValue
    ) {
      alert("Please verify you are a human!");
      return;
    }

    // Send data to Firestore
    try {
      const testimonialDoc = doc(db, "formData", "testimonialDoc");
      await updateDoc(testimonialDoc, {
        testimonials: arrayUnion({
          fullName,
          designation,
          companyName,
          companyEmail,
          positiveReview,
          feedback,
          timestamp: new Date(),
          isVisible: true,
        }),
      });

      // Clear the form
      setFullName("");
      setDesignation("");
      setCompanyName("");
      setCompanyEmail("");
      setPositiveReview("");
      setFeedback("");
      recaptchaRef.current.reset();

      alert("Testimonial submitted successfully! Thank you for your feedback.");
    } catch (error) {
      console.error("Error submitting testimonial: ", error);
      alert(
        "An error occurred while submitting the testimonial. Please try again later."
      );
    }
  };

  const handleReset = () => {
    setFullName("");
    setDesignation("");
    setCompanyName("");
    setCompanyEmail("");
    setPositiveReview("");
    setFeedback("");
    setCaptchaValue(""); // Reset captcha value
    recaptchaRef.current.reset(); // Reset ReCAPTCHA
  };

  return (
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5" style={{ background: "#F5F5F5" }}>
        <div className="text-center mx-auto" style={{ maxWidth: 900 }}>
          <form onSubmit={handleSubmit}>
            <div className="section-title text-center position-relative pb-3">
              <h1 className="mb-0">Testimonial-Form</h1>
            </div>
            <div className="row g-3 pt-5 pb-3 justify-content-center">
              <div className="col-md-9">
                <div className="col-xl-12">
                  <input
                    type="text"
                    className="form-control border-0 mb-2"
                    placeholder="Your Full Name"
                    style={{ height: 55 }}
                    name="name"
                    required
                    autoComplete="name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control border-0 mb-2"
                    placeholder="Your Designation"
                    style={{ height: 55 }}
                    name="designation"
                    required
                    autoComplete="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
                <div className="col-12 pb-3">
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Your Company's Name"
                    style={{ height: 55 }}
                    name="companyName"
                    required
                    autoComplete="organization"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="col-12 pb-3">
                  <input
                    type="email"
                    className="form-control border-0"
                    placeholder="Your Company's Email"
                    style={{ height: 55 }}
                    name="companyEmail"
                    autoComplete="email"
                    value={companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                  />
                </div>
                <div className="col-12 pb-3">
                  <textarea
                    className="form-control border-0"
                    style={{ height: 90 }}
                    value={positiveReview}
                    onChange={(e) => setPositiveReview(e.target.value)}
                    placeholder="Positive Review"
                    required
                  ></textarea>
                </div>
                <div className="col-12 pb-3">
                  <textarea
                    className="form-control border-0"
                    style={{ height: 90 }}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Feedback to the Company"
                  ></textarea>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LfU56wpAAAAAKIWM7IqySvgIUuxH-BGaCDbIT_7"
                  onChange={handleCaptchaResponseChange}
                />
              </div>
              <div className="col-12 pt-4 d-flex flex-column flex-md-row justify-content-center">
                <button
                  name="submit"
                  className="btn btn-primary btn-lg py-3 mb-2 mb-md-0"
                  type="submit"
                  style={{
                    background: "#f3b457",
                    width: "auto",
                    maxWidth: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "1rem",
                  }} // Adjust width for better control
                >
                  Send Testimonial
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg py-3"
                  onClick={handleReset}
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }} // Adjust width for better control
                >
                  Clear Form
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TestimonialForm;
