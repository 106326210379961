import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import "./singlePost.css";
import { TailSpin } from "react-loader-spinner";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function SinglePost() {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const { title } = useParams(); // Get the title from the URL
  const [editorState, setEditorState] = useState(null);
  const navigate = useNavigate();

  const decodeTitle = (encodedTitle) => {
    // Decode the URL-encoded string
    const decodedTitle = decodeURIComponent(encodedTitle);
    // Replace underscores with spaces
    return decodedTitle.replace(/_/g, " ");
  };

  const encodeLabel = (label) => {
    const cleanLabel = label.replace(/[^a-zA-Z0-9\s_]/g, '');
    return encodeURIComponent(cleanLabel.replace(/ /g, '_'));
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const decodedTitle = decodeTitle(title);
        const blogDocRef = doc(db, "blogs", "blogDoc");
        const blogDocSnap = await getDoc(blogDocRef);

        if (blogDocSnap.exists()) {
          const blogsData = blogDocSnap.data().blogs;
          // Create a normalized version of the decoded title for comparison
          const normalizedDecodedTitle = decodedTitle
            .toLowerCase()
            .replace(/[^a-z0-9]/g, "");

          // Find the post by comparing normalized titles
          const foundPost = blogsData.find((blog) => {
            const normalizedBlogTitle = blog.title
              .toLowerCase()
              .replace(/[^a-z0-9]/g, "");
            return normalizedBlogTitle === normalizedDecodedTitle;
          });

          if (foundPost) {
            setPost(foundPost);
            // Check if the content is in Draft.js raw format or plain text
            if (foundPost.content) {
              try {
                // Try to parse the content as JSON (Draft.js raw format)
                const contentState = convertFromRaw(
                  JSON.parse(foundPost.content)
                );
                setEditorState(EditorState.createWithContent(contentState));
              } catch (error) {
                console.log(
                  "Content is not in Draft.js raw format, treating as plain text"
                );
                // If parsing fails, set the content as plain text
                setEditorState(EditorState.createWithText(foundPost.content));
              }
            } else {
              // If there's no content, create an empty EditorState
              setEditorState(EditorState.createEmpty());
            }
          } else {
            console.log("No matching blog post found.");
            setError("Post not found");
          }
        } else {
          console.log("No such document!");
          setError("Blog data not found");
        }
      } catch (error) {
        console.error("Error fetching blog post: ", error);
        setError("Error fetching blog post");
      }
    };

    fetchPost();
  }, [title]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!post) {
    return (
      <div className="loader-container">
        <TailSpin height="80" width="80" color="#00BFFF" ariaLabel="loading" />
      </div>
    );
  }

  const keywords = [post.title, post.labels, "blog", "article"]
    .filter(Boolean)
    .join(", ");

  // Split content into paragraphs
  const contentParagraphs = post.content
    .split("\n")
    .filter((para) => para.trim() !== "");

  const handleShare = () => {
    const blogUrl = window.location.href;

    if (navigator.share) {
      navigator
        .share({
          title: post.title,
          text: post.metaDescription,
          url: blogUrl,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback for browsers that don't support navigator.share
      navigator.clipboard
        .writeText(blogUrl)
        .then(() => alert("Link copied to clipboard!"))
        .catch((error) => console.error("Error copying link: ", error));
    }
  };

  const handleLabelClick = (label) => {
    navigate(`/blogs/label/${encodeLabel(label.trim())}`);
  };

  return (
    <>
      <Helmet>
        <title>{`${post.title} | Enrope Solutions`}</title>
        <meta
          name="description"
          content={post.metaDescription || `Read about ${post.title}`}
        />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <div className="singlePost container">
        <div className="singlePostWrapper">
          <img className="singlePostImg" src={post.imageUrl} alt={post.title} />
          <h1 className="singlePostTitle">{post.title}</h1>
          <div className="singlePostInfo">
            <span>
              Author:
              <b className="singlePostAuthor">
                <Link className="link" to={`/blog?username=${post.author}`}>
                  {post.author}
                </Link>
              </b>
            </span>
            <span>{post.date}</span>
          </div>
          <div className="singlePostContent">
            <div className="postContent">
              {editorState ? (
                <Editor
                  editorState={editorState}
                  readOnly={true}
                  toolbarHidden
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                />
              ) : (
                contentParagraphs.map((paragraph, index) => (
                  <p key={index} className="postParagraph">
                    {paragraph}
                  </p>
                ))
              )}
            </div>
            <div className="singlePostSidebar">
              <button onClick={handleShare} className="shareButton2">
                Share
              </button>
              <div className="labelsList">
                <h3>Labels</h3>
                <ul>
            {post.labels.split(",").map((label, index) => (
              <li key={index} onClick={() => handleLabelClick(label.trim())} className="clickableLabel">
                {label.trim()}
              </li>
            ))}
          </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
