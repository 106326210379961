import React from "react";
import img from "../img/about.webp";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5
                  className="fw-bold text-uppercase"
                  style={{ color: "#f3b457" }}
                >
                  About Us
                </h5>
                <h1 className="mb-0">
                  The Best IT Solution With 10 Years of Experience
                </h1>
              </div>
              <p className="mb-4">
                Enrope Solutions is a leading provider of comprehensive
                solutions for institutions and businesses, established in 2019.
                We specialise in website and mobile app development, software
                development, ID card solutions, digital marketing, branding,
                design and procurement.
                <br />
                <br />
                Our mission is to deliver accessible, innovative, and practical
                solutions tailored to your needs. We collaborate closely with
                our clients to understand their challenges and transform their
                vision into reality. Our expertise and commitment to excellence
                empower you to enhance efficiency, achieve better results, and
                foster sustainable growth.
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i
                      className="fa fa-check me-3"
                      style={{ color: "#f3b457" }}
                    />
                    Innovative Solutions
                  </h5>
                  <h5 className="mb-3">
                    <i
                      className="fa fa-check me-3"
                      style={{ color: "#f3b457" }}
                    />
                    Professional Staff
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i
                      className="fa fa-check me-3"
                      style={{ color: "#f3b457" }}
                    />
                    24/7 Support
                  </h5>
                  <h5 className="mb-3">
                    <i
                      className="fa fa-check me-3"
                      style={{ color: "#f3b457" }}
                    />
                    Fair Prices
                  </h5>
                </div>
              </div>
              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="d-flex align-items-center justify-content-center rounded"
                  style={{ width: 60, height: 60, background: "#f3b457" }}
                >
                  <a href="tel:+91 7303 441 550">
                    <i className="fa fa-phone text-white" />
                  </a>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="mb-0" style={{ color: "#f3b457" }}>
                    <a
                      href="tel:+91 7303 441 550"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      +91 7303 441 550
                    </a>
                  </h4>
                </div>
              </div>
              <Link
                to="/quote"
                className="text-white py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
                style={{ background: "#f3b457" }}
              >
                Request A Quote
              </Link>
            </div>
            <div className="col-lg-5" style={{ minHeight: 500 }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={img}
                  style={{ objectFit: "cover" }}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
