import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img from "../img/logo.webp";
import { analytics } from "../firebase.js";
import { logEvent } from "firebase/analytics";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { db } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";

const Footer = () => {
  const [clients, setClients] = useState([]); // state to store the fetched clients

  useEffect(() => {
    const fetchClients = async () => {
      const docRef = doc(db, 'clients', 'clientDoc');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setClients(docSnap.data().clients);
      } else {
        console.log("No such document!");
      }
    };

    fetchClients();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          className: "slide-pos",
          autoplaySpeed: 2000,
          speed: 1000,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5 mb-5">
          <div className="bg-white">
          <Slider {...settings}>
              {clients.map((client, index) => (
                <div className="item" key={index}>
                  <img
                    src={client.clientLogo}
                    alt=""
                    style={{ width: "120px", height: "auto" }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ background: "black" }}
      >
        <div className="container-fluid">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div
                className="d-flex flex-column align-items-center justify-content-center text-center h-100 p-4"
                style={{ background: "#F5F5F5" }}
              >
                <Link to="/" className="navbar-brand p-0">
                  <img
                    src={img}
                    alt="Logo"
                    className="navbar-brand p-0"
                    width="280px"
                  />
                </Link>
                <p className="mt-3 mb-4" style={{ color: "black" }}>
                  We offer Software Solutions, School Solutions, Print
                  Solutions, School ERP, E-learning, Digital Marketing,
                  Branding, Designing, Procurement Services, Event Conduction,
                  Planning and Management to Institutions, Businesses, and
                  Health Care Units.
                </p>
                {/*          <form action="">
                      <div class="input-group">
                          <input type="text" class="form-control border-white p-3" placeholder="Your Email">
                          <button class="" style="background:black;color:white;">Sign Up</button>
                      </div>
                  </form> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5 p-2">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="fa fa-map-marker-alt text-warning me-2 mt-1" />
                    <p className="mb-0">Unit B 002, B 91, Sector 2,<br/> Noida, Uttar Pradesh</p>
                  </div>
                  <div className="d-flex mb-2 mt-2" style={{ marginRight: 80 }}>
                    <i className="fa fa-envelope-o text-warning mt-1 me-2" />
                    <Link
                      to="mailto:contact@enropesolutions.com"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      contact@enropesolutions.com
                    </Link>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="fa fa-phone text-warning me-2 mt-1" />
                    <Link
                      to="tel:+91 7303 441 550"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      +91 7303 441 550
                    </Link>
                  </div>
                  <div className="d-flex mt-4" style={{ marginTop: 0 }}>
                    <Link
                      className="text-white btn-square me-2"
                      to="https://twitter.com/enropesolutions"
                      target="_blank"
                      style={{ background: "#f3b457" }}
                      rel="noreferrer"
                    >
                      <i
                        className="fab fa-twitter fw-normal"
                        style={{ marginTop: 10 }}
                      />
                    </Link>
                    <Link
                      className="text-white btn-square me-2"
                      to="https://www.facebook.com/enropesolutions"
                      target="_blank"
                      style={{ background: "#f3b457" }}
                      rel="noreferrer"
                    >
                      <i
                        className="fab fa-facebook-f fw-normal"
                        style={{ marginTop: 10 }}
                      />
                    </Link>
                    <Link
                      className="text-white btn-square me-2"
                      to="https://www.linkedin.com/company/enrope-solutions/"
                      target="_blank"
                      style={{ background: "#f3b457" }}
                      rel="noreferrer"
                    >
                      <i
                        className="fab fa-linkedin-in fw-normal"
                        style={{ marginTop: 10 }}
                      />
                    </Link>
                    <Link
                      className="text-white btn-square"
                      to="https://www.instagram.com/enropesolutions/"
                      target="_blank"
                      style={{ background: "#f3b457" }}
                      rel="noreferrer"
                    >
                      <i
                        className="fab fa-instagram fw-normal"
                        style={{ marginTop: 10 }}
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5"
                  style={{ marginLeft: 0 }}
                >
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    {/* <a class="text-light mb-2" to="#"><i
                                  class="bi bi-arrow-right text-primary me-2"></i>Latest Blog</Link> */}
                    <Link className="text-light mb-2" to="/privacy">
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Privacy Policy
                    </Link>
                    <Link className="text-light mb-2" to="/terms">
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Terms and Conditions
                    </Link>
                    <Link className="text-light mb-2" to="/cancellation">
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Cancellation and Refund
                    </Link>
                    <Link className="text-light mb-2" to="/shipping">
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Shipping and Delivery
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/pricing"
                      onClick={() => {
                        logEvent(analytics, "Pricing_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Pricing
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link
                      className="text-light mb-2"
                      to="/"
                      onClick={() => {
                        logEvent(analytics, "Footer_Home_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Home
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/about"
                      onClick={() => {
                        logEvent(analytics, "Footer_About_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      About
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/features"
                      onClick={() => {
                        logEvent(analytics, "Footer_Features_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Features
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/services"
                      onClick={() => {
                        logEvent(analytics, "Footer_Services_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Services
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/testimonial"
                      onClick={() => {
                        logEvent(analytics, "Footer_Testimonial_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Testimonial
                    </Link>
                    <Link
                      className="text-light mb-2"
                      to="/contact"
                      onClick={() => {
                        logEvent(analytics, "Footer_Contact_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Contact
                    </Link>
                    <Link
                      className="text-light"
                      to="/quote"
                      onClick={() => {
                        logEvent(analytics, "Footer_Quote_page", {
                          page_location: window.location.to,
                          page_path: window.location.pathname,
                          page_title: document.title,
                        });
                      }}
                    >
                      <i className="bi bi-arrow-right text-warning me-2" />
                      Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "black" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  ©{" "}
                  <Link className="text-white" to="*">
                    Enrope Solutions Private Limited
                  </Link>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
