import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <>
      <div className="container-fluid py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container pb-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: 900 }}
          >
            <h5
              className="fw-bold text-uppercase mt-5"
              style={{ color: "#f3b457" }}
            >
              Our Services
            </h5>
            <h1 className="mb-0">
              Custom IT Solutions for Your Successful Business
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-code"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Web App. Development</h4>
                <p className="m-0">
                Elevate your online presence with expert web design & development. 
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/web-app-development"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-mobile-alt"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Mobile App. Development</h4>
                <p className="m-0">
                Delhi NCR's top mobile app developer. Build feature-rich iOS & Android apps to skyrocket your business.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/mobile-app-development"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-user-plus"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Digital Marketing</h4>
                <p className="m-0">
                  We assist various sectors in establishing their digital
                  presence and in social media platforms, as well as
                  establishing themselves as a brand.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/digital-marketing"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-id-badge"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">ID Card Print</h4>
                <p className="m-0">
                Enhance brand security & streamline access control with custom PVC ID cards, lanyards & printing services.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/id-card-print"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-copyright"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Designing &amp; Branding</h4>
                <p className="m-0">
                  Our excellent design contributes to the reinforcement of a
                  brand's identity while also providing guidance and inspiration
                  for the design process.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/brand-designs"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-users"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Social Media Management</h4>
                <p className="m-0">
                Elevate your brand's online presence in Delhi NCR! Experienced social media managers will create engaging content, drive growth, and boost brand awareness.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/social-media-management"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="service-item rounded d-flex flex-column align-items-center justify-content-center text-center"
                style={{ background: "#F5F5F5" }}
              >
                <div className="service-icon">
                  <i
                    className="fa fa-google"
                    style={{ color: "white", fontSize: 22 }}
                  />
                </div>
                <h4 className="mb-3">Google Ads</h4>
                <p className="m-0">
                Boost your business with expert Google Ads management. Our PPC specialists craft targeted campaigns, maximizing your ROI and driving sales.
                </p>
                <Link
                  className="btn btn-lg rounded text-white"
                  to="/google-ads"
                  style={{ background: "#f3b457" }}
                >
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="position-relative rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5"
                style={{ background: "#F5F5F5" }}
              >
                <h3 className="mb-3" style={{ color: "black" }}>
                  Call Us For Quote
                </h3>
                {/*     <p class="text-white mb-3">Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor est
                      magna stet eirmod</p> */}
                <h2 className="mb-0" style={{ color: "black" }}>
                  <a
                    href="tel:+91 7303 441 550"
                    style={{ color: "#f3b457", textDecoration: "none" }}
                  >
                    +91 7303 441 550
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
