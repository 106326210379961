import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import Post from "../post/Post";
import "./posts.css";

export default function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const blogCollectionRef = collection(db, 'blogs');
        const querySnapshot = await getDocs(blogCollectionRef);
        
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {   
          const blogsData = doc.data().blogs;
          if (Array.isArray(blogsData)) {
            fetchedPosts.push(...blogsData);
          }
        });

        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Error fetching blog posts: ", error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="posts">
      {posts.map((post, index) => (
        <Post key={index} post={post} />
      ))}
    </div>
  );
}